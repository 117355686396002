export const BLUE_LAYER_ACTIVITY_MODAL = {
  title: "Tilføj kort",
  introduction: `
    <p class='mb-6'>Her kan du opdele dit værditilbud i flere lag altså lave eks. varegrupper eller aktiviteter ved at opdele dem i forskellige ”kort”. Disse kort kan du så give forskellige farver. Disse kan så allokere til de byggesten som er relevante for netop disse varegrupper eller aktiviteter. Farverne vil så fremgå i hver enkelt byggesten, hvor de er indplaceret.</p>
    <p class='mb-6'>Dette vil give et bedre overblik over, hvor ressourcerne bliver brugt og hvor kan der optimeres.</p>
  `,
  inputLabel: "Overskrift",
  colorPickerLabel: "Vælg farve til kort",
  SubmitButtonText: "Gem",
  CancelButtonText: "Slet"
}

export const YELLOW_LAYER_ACTIVITY_MODAL = {
  title: "Tilføj kort",
  introduction: `
    <p class='mb-6'>Hvis det giver mening, kan du opdele den sociale værdi i flere lag altså lave eks. forskellige afdelinger i virksomheden, ved at opdele dem i forskellige ”kort”. Disse kort kan du så give forskellige farver. Disse kan så allokeres til de byggesten som er relevante for netop disse afdelinger. Farverne vil så fremgå i hver enkelt byggesten, hvor de er indplaceret.</p>
  `,
  inputLabel: "Overskrift",
  colorPickerLabel: "Vælg farve til kort",
  SubmitButtonText: "Gem",
  CancelButtonText: "Slet"
}

export const GREEN_LAYER_ACTIVITY_MODAL = {
  title: "Tilføj kort",
  introduction: `
    <p class='mb-6'>Her kan du opdele den funktionelle enhed i undergrupper. Således kan man behandle flere enheder i samme model. Giver det mening at gruppere dem og tale om homogene varegrupper? Ved at opdele dem i forskellige ”kort” med forskellige farver. Disse kan så allokeres til de byggesten som er relevante for netop disse enheder. Farverne vil så fremgå i hver enkelt byggesten, hvor de er indplaceret.</p>
  `,
  inputLabel: "Overskrift",
  colorPickerLabel: "Vælg farve til kort",
  SubmitButtonText: "Gem",
  CancelButtonText: "Slet"
}

export const LAYER_TAG_MODAL = {
  title: "Kanaler",
  introduction: `
    <p class='mb-6'>Kanaler bekriver hvordan du når dit kundesegment, kommunikerer med dem og leverer dit værdiudsagn. Når vi kender vores kundesegment og værdiudsagn, kan vi undersøge hvordan vi når kunden gennem marketing, salg, kundeservice – altså hele kundeoplevelsen.</p>
    <p class='mb-6'>Samtidig bør du tænke grundigt over, hvilke kanaler kunden foretrækker at benytte, hvilke du bruger i øjeblikket, hvilke der virker bedst og hvilke der giver økonomisk mening. Kanaler kan være både fysiske og digitale, hvor du kan få et godt overblik gennem PESO modellen.</p>
    <p class='mb-6'><strong>Følgende spørgsmål kan hjælpe dit arbejde:</strong></p>
    <p class='mb-6'>
      <ol class='list-decimal'>
        <li>Jeg vil nå mine kunder gennem (kanaler, medier, SoMe)…</li>
        <li>Jeg vil optimere mine kunders oplevelse ved at…</li>
        <li>Mine hoved salgstale er…</li>
        <li>Jeg vil anvende følgende marketing mix…</li>
      </ol>
    </p>
  `,
  inputLabel: "Overskrift",
  colorPickerLabel: "Vælg farve til kort",
  SubmitButtonText: "Gem",
  CancelButtonText: "Slet",
  selectLabel: "Aktivitet",
  textareaLabel: "Beskrivelse"
}

// need add other modal datas;

export const ACTIVITY_MODAL_DATA: any = {
  blue_layer: BLUE_LAYER_ACTIVITY_MODAL,
  yellow_layer: YELLOW_LAYER_ACTIVITY_MODAL,
  green_layer: GREEN_LAYER_ACTIVITY_MODAL
}

export const TAG_MODAL_DATA: any = {
  blue_layer: LAYER_TAG_MODAL,
  yellow_layer: LAYER_TAG_MODAL,
  green_layer: LAYER_TAG_MODAL
}


export const RED_LAYER_DESCRIPTION: any = `
  <p class="mb-6">Verdensmålene udgør 17 konkrete mål og 169 delmål, som forpligter alle FN’s 193 medlemslande til helt at afskaffe fattigdom og sult i verden, reducere uligheder, sikre god uddannelse og bedre sundhed til alle, anstændige jobs og mere bæredygtig økonomisk vækst.</p>
  <p class="mb-6">Verdensmålene gælder for alle lande, byer, virksomheder og individer  – både rige og fattige – med højde for landenes forskellige udgangspunkt. De store udfordringer, vi står overfor i dag, som bl.a. social, økonomisk og politisk marginalisering, stigende ungdomsarbejdsløshed, ulighed, fattigdom, fødevareusikkerhed, mangel på, eller ulige adgang til grundlæggende naturressourcer, miljøforurening, klimaforandringer og ikke-lydhøre regeringer. De er alle nationale udfordringer med store regionale og globale konsekvenser, og er derfor nødvendige at løse i fællesskab.</p>
  <p class="mb-6">Den nye dagsorden anerkender således, at social, økonomisk og miljømæssig udvikling, fred, sikkerhed og internationalt samarbejde er tæt forbundne, og at det kræver en integreret indsats at opnå holdbare udviklingsresultater.</p>
  <p class="mb-6">Derfor er det vigtigt at vi alle hjælper til og gør en indsats.</p>
  <p class="mb-6">Modellen her giver en introduktion til FN17 Verdensmålene hver for sig, der kan så udvælges hvor man kan gøre en forskel som virksomhed. Under hvert af de udvalgte Verdensmål er der en række delmål, som hver især kan understøtte Verdensmålene. Til hvert af delmålene kan man indikere hvilke tiltage man gør som virksomhed og dermed støtte i kampen mod de store verdensudfordringer.</p>
`;

export const LAYER_DESCRIPTION: any = {
  blue_layer: `
    <p class="mb-6 text-center">
        <strong>DEN ØKONOMISKE BUNDLINJE</strong>
    </p>
    <p class="mb-6">
        Business Model Canvas består af ni nøgleelementer, der hver især hjælper med at beskrive og strukturere en virksomheds forretningsmodel. Her er en detaljeret gennemgang af hvert element, der kan hjælpe med at forstå og formulere en forretningsplan.
    </p>
    <p class="mb-6">
        Modellen er opdelt i en Ekstern og Intern del. I den eksterne del beskriver du, hvad du sælger, til hvem og hvordan – her ser du på: Værditilbud, Kundegrupper, Kanaler, Kunderelationer og Indtægter. I den interne del beskriver du, hvad det kræver af din virksomhed for at kunne levere dit produkt eller dine ydelser til dine kunder – her ser du på: Nøgleaktiviteter, Nøgleressourcer, Nøglepartnere og Omkostningsstruktur
    </p>
    <p class="mb-6">
        Ved at bruge disse ni elementer kan din forretningsmodel skitseres på en struktureret måde. Dette hjælper med at forstå, hvordan alle dele af din virksomhed hænger sammen, og hvordan der kan skabes værdi på lang sigt.
    </p>
    <p class="mb-6">
        <strong><u>Ekstern Side: Kunder og Marked</u></strong>
    </p>
    <p class="mb-6">
        <strong>VÆRDITILBUD</strong>
    </p>
    <p class="mb-6">
        Værditilbuddet er hjørnestenen i forretningsmodellen. Det definerer, hvordan virksomheden skaber værdi for sine kunder gennem unikke produkter eller tjenester, som opfylder kundernes behov, løser deres problemer eller skaber nye muligheder.
    </p>
    <p class="mb-6">
        Du bør overveje: Hvilke problemer løser dine produkter eller tjenester? Hvorfor skal kunder vælge mig fremfor konkurrenterne? Hvilken innovation, pris eller kvalitet adskiller mig fra andre?
    </p>
    <p class="mb-6">
        <strong>KUNDEGRUPPER</strong>
    </p>
    <p class="mb-6">
        Kundegrupper repræsenterer de forskellige segmenter, virksomheden skaber værdi for. Fokus er på at identificere de fællestræk, der binder gruppen sammen, såsom behov, opgaver eller udfordringer, de forsøger at løse. En virksomhed kan have flere kundegrupper, hver med specifikke karakteristika og krav.
    </p>
    <p class="mb-6">
        Du bør overveje: Hvem ønsker jeg at sælge til? Hvilke behov opfylder jeg for kundegruppen? Hvilke kundegrupper skal jeg prioritere for at skabe størst værdi?
    </p>
    <p class="mb-6">
        <strong>KANALER</strong>
    </p>
    <p class="mb-6">
        Kanaler omhandler måden, virksomheden når sine kunder og leverer værditilbuddet på. Det kan være både fysiske og digitale kanaler, og det er afgørende at vælge de mest effektive og økonomisk fordelagtige.
    </p>
    <p class="mb-6">
        Du bør overveje: Hvordan kommunikerer jeg med kunderne? Hvilke salgskanaler er mest effektive? Hvordan optimerer jeg kundeoplevelsen?
    </p>
    <p class="mb-6">
        <strong>KUNDERELATIONER</strong>
    </p>
    <p class="mb-6">
        Dette element handler om den type forhold, virksomheden har eller ønsker at have med sine kunder. Relationerne kan variere fra personlige til automatiserede og afhænger af branchens karakter og kundernes forventninger.
    </p>
    <p class="mb-6">
        Du bør overveje: Hvordan opbygger og fastholder jeg relationer til mine kunder? Hvilken type kundeservice passer og forventer mine kunder? Hvordan skaber jeg loyalitet blandt mine kunder?
    </p>
    <p class="mb-6">
        <strong>INDTÆGTSSTRØMME</strong>
    </p>
    <p class="mb-6">
        Dette element omhandler, hvordan der kan skabes indtægter fra kundegrupperne og det er her der tages stilling til hvilke pris-/salgsmodeller, der passer bedst til de specifikke kundegrupper og ydelser.
    </p>
    <p class="mb-6">
        Du bør overveje: Hvordan tjener jeg penge fra de forskellige kundegrupper? Hvordan kan jeg maksimere mine indtægter? Kan min valgte prisstrategi være med til at differentiere mig fra konkurrenterne?
    </p>
    <div style="text-align:center">
        <hr style="width:100%; height:1.5pt" />
    </div>
    <p class="mb-6">
        <strong><u>Intern Side: Organisation og Ressourcer</u></strong>
    </p>
    <p class="mb-6">
        <strong>NØGLEAKTIVITETER</strong>
    </p>
    <p class="mb-6">
        Nøgleaktiviteterne omfatter de vigtigste handlinger, virksomheden skal udføre for at levere sit værditilbud, nå sine kunder og generere indtægter. Det kan inkludere produktion, markedsføring, distribution eller kundeservice.
    </p>
    <p class="mb-6">
        Du bør overveje: Hvilke opgaver er kritiske for at levere værdi? Hvilke processer skal optimeres? Hvordan kan jeg sikre en effektiv drift?
    </p>
    <p class="mb-6">
        <strong>NØGLERESSOURCER</strong>
    </p>
    <p class="mb-6">
        Ressourcerne dækker alt, hvad virksomheden har brug for at kunne levere sit værditilbud. Det kan være medarbejdere, teknologi, kapital eller viden.
    </p>
    <p class="mb-6">
        Du bør overveje: Hvilke ressourcer er nødvendige for at drive virksomheden? Hvordan finansierer jeg mine aktiviteter? Skal jeg beskytte mine intellektuelle rettigheder?
    </p>
    <p class="mb-6">
        <strong>NØGLEPARTNERE</strong>
    </p>
    <p class="mb-6">
        Nøglepartnere er de leverandører og samarbejdspartnere, der er afgørende for, at din forretningsmodel fungerer. Ingen virksomhed kan operere fuldstændigt alene, og det er ofte nødvendigt at trække på eksterne netværk, platforme eller outsourcing for at sikre, at alle nøgleaktiviteter og ressourcer er på plads.
    </p>
    <p class="mb-6">
        Det er ikke altid fornuftigt eller økonomisk fordelagtigt at eje alle ressourcerne selv. I mange brancher kan strategiske partnerskaber være en bedre løsning, da de kan bidrage med specialiseret viden, produkter eller tjenester. Disse partnerskaber hjælper med at optimere dit værditilbud, reducere omkostninger og forbedre effektiviteten i virksomheden.
    </p>
    <p class="mb-6">
        Samarbejdet med nøglepartnere kan omfatte alt fra leverandører af råmaterialer, distributionsnetværk og teknologiske platforme til samarbejdspartnere, der hjælper med markedsføring eller innovation. Valget af partnere bør understøtte din virksomheds mål og værdier.
    </p>
    <p class="mb-6">
        Du bør overveje: Hvilke samarbejdspartnere er nødvendige for at levere dit værditilbud? Hvordan og hvor finder du disse partnere? Hvilke opgaver eller ressourcer kan eller bør du outsource?
    </p>
    <p class="mb-6">
        <strong>OMKOSTNINGSSTRUKTUR</strong>
    </p>
    <p class="mb-6">
        Omkostningsstrukturen dækker de udgifter, der er nødvendige for at drive forretningsmodellen. Det er vigtigt at analysere faste og variable omkostninger samt vurdere balancen mellem omkostningseffektivitet og værdi.
    </p>
    <p class="mb-6">
        Du bør overveje: Hvilke omkostninger er mest kritiske? Hvordan kan jeg minimere udgifter uden at gå på kompromis med kvaliteten? Hvordan står min omkostningsstruktur i forhold til konkurrenternes?
    </p>
    <p class="mb-6">
        <br style="page-break-before:always; clear:both" />
    </p>
  `,
  yellow_layer: `
    <p class="mb-6 text-center">
        <strong>DEN SOCIALE BUNDLINJE</strong>
    </p>
    <p class="mb-6">
        Den sociale bundlinje repræsenterer en virksomheds sociale ansvar og dens bidrag til samfundet, medarbejderne og andre interessenter. Denne dimension handler om at fremme trivsel, inklusion, retfærdighed og langsigtede relationer, både internt i virksomheden og eksternt i det omkringliggende samfund.
    </p>
    <p class="mb-6">
        Når den sociale bundlinje anvendes, bør virksomheder fokusere på at identificere og beskrive, hvordan deres aktiviteter skaber positive sociale resultater. Dette kan omfatte engagement i lokalsamfundet, initiativer for medarbejdertrivsel, støtte til mangfoldighed og inklusion, og tiltag der sikrer fair arbejdsforhold.
    </p>
    <p class="mb-6">
        Det er også vigtigt at overveje, hvordan virksomheden håndterer sociale udfordringer som ulighed, arbejdsmiljø, eller uddannelsesmuligheder. Den sociale bundlinje er derfor ikke kun et redskab til at opnå økonomisk succes, men også en måde at sikre, at virksomhedens værdier og aktiviteter skaber reel værdi for mennesker og samfundet som helhed.
    </p>
    <p class="mb-6">
        Ved at integrere disse aspekter i forretningsmodellen styrkes virksomhedens sociale kapital, hvilket kan føre til øget loyalitet fra kunder og medarbejdere, forbedret omdømme og en mere bæredygtig og langsigtet vækststrategi.
    </p>
    <p class="mb-6">
        Følgende 9 hovedpunkter er indeholdt i modellen.
    </p>
    <p class="mb-6">
        <strong>SOCIAL VÆRDI</strong>
    </p>
    <p class="mb-6">
        Social værdi kan sammenlignes med virksomhedens vision, mission og formål, udover at tjene penge – altså hvad virksomheden gør for samfundet generelt.
    </p>
    <p class="mb-6">
        Du bør overveje: Hvilke ting gøres for det omliggende samfund? Hvad er jeres eksistensberettigelse? Hvad er planen i fremtiden?
    </p>
    <p class="mb-6">
        <strong>SLUTBRUGER</strong>
    </p>
    <p class="mb-6">
        Slutbrugeren er personen, der bruger produktet, og denne del handler derfor om, hvordan virksomheden bidrager positivt til personens liv. Ligesom i den økonomiske del, handler det om at kende målgruppens behov.
    </p>
    <p class="mb-6">
        Du bør overveje: Hvordan slutbrugeren bruger produktet eller servicen? Hvilke fordele har slutbruger af at bruge produktet eller servicen? Vil der ske udvikling på dette punkt?
    </p>
    <p class="mb-6">
        <strong>KULTUR</strong>
    </p>
    <p class="mb-6">
        Virksomheden har ikke succes, hvis samfundet ikke er med, og handler derfor om hvordan virksomheden kan have en positiv påvirkning, f.eks. gennem nonprofitorganisationer og andre sociale agendaer.
    </p>
    <p class="mb-6">
        Du bør overveje: Støttes organisationer som er en del af samfundet som ikke handler af økonomisk grund? Hvilken kultur støtter eller taler værditilbuddet ind i? Skal der ske ændringer?
    </p>
    <p class="mb-6">
        <strong>RÆKKEVIDDE</strong>
    </p>
    <p class="mb-6">
        Rækkevidde er en vurdering af, hvor langt og omfattende virksomhedens påvirkning rækker. F.eks. i form af antallet af lande eller kulturer, og i hvilken grad virksomheden tilpasser til lokale forhold.
    </p>
    <p class="mb-6">
        Du bør overveje: At beskriv de områder virksomheden opererer i, Hvorledes har virksomheden tilpasset sig i lokalmiljøet? Skal der ske ændringer?
    </p>
    <p class="mb-6">
        <strong>SOCIALE FORDELE</strong>
    </p>
    <p class="mb-6">
        Sociale fordele er de aktive tiltag virksomheden gør for et positivt socialt aftryk. Ligesom ovenstående er denne byggesten i høj grad påvirket af den enkelte virksomhed.
    </p>
    <p class="mb-6">
        Du bør overveje: Hvilke aktiviteter du har ud over de andre sociale tiltag? Hvilken positiv indvirkning har I på bruger, forbruger og værdikæde?
    </p>
    <p class="mb-6">
        <strong>ORGANISATION </strong>
    </p>
    <p class="mb-6">
        Organisation omhandler strukturen og hierarkiet i virksomheden. Det kunne f.eks. være, hvordan den træffer beslutninger, og i hvor høj grad der er gennemsigtighed i virksomheden.
    </p>
    <p class="mb-6">
        Du bør overveje: Hvorvidt foretages beslutningerne i grupper eller i samråd? Hvorledes informeres om de beslutninger der træffes? Hvad er organisationsstrukturen? Er der ting der ønskes ændret?
    </p>
    <p class="mb-6">
        <strong>MEDARBEJDERE</strong>
    </p>
    <p class="mb-6">
        Denne del omhandler medarbejdernes rolle og vilkår i virksomheden, indenfor f.eks. løn, demografi (alder/køn), pension og uddannelse – og andre programmer internt i virksomheden, der sikrer medarbejderne et godt liv.
    </p>
    <p class="mb-6">
        Du bør overveje: At beskriv personalepolitikken (Sundhed og sikkerhed), Hvor kommer dine medarbejdere fra? Hvorledes motiveres ansatte til at blive og dygtiggøre sig? Hvad tilbydes ud over løn for at knytte ansatte til virksomheden? Er der planer om ændringer?
    </p>
    <p class="mb-6">
        <strong>LOKALMILJØ</strong>
    </p>
    <p class="mb-6">
        Byggestenen handler om virksomhedens forhold til det/de lokalmiljøer den er en del af, eller virksomhedens leverandører er en del af. Hvis virksomheder har flere placeringer, i samme eller flere lande, gennemgås de separat.
    </p>
    <p class="mb-6">
        Du bør overveje: Hvad gør du for lokalmiljøet? Sponsorerer virksomheden private, organisationer eller foreninger? Ønsker du at øge indsatsen i fremtiden?
    </p>
    <p class="mb-6">
        <strong>SOCIALE PÅVIRKNINGER</strong>
    </p>
    <p class="mb-6">
        Sociale påvirkninger kan indeholde elementer, såsom medarbejdernes arbejdstid, helbred og sikkerhed – men det afhænger meget af virksomheden, hvilke elementer man bør fokusere på.
    </p>
    <p>
        Du bør overveje: Hvilken social påvirkning har I på jeres forbruger, værdikæde? Er der specielle forhold som gælder sikkerhed, sundhed eller velbefindende? <br style="page-break-before:always; clear:both" />
    </p>
  `,
  green_layer: `
    <p class="mb-6 text-center">
        <strong>DEN MILJØMÆSSIGE BUNDLINJE</strong>
    </p>
    <p class="mb-6">
        <strong>FUNKTIONEL ENHED </strong>
    </p>
    <p class="mb-6">
        Denne del handler om at definere en klar enhed, som virksomheden bruger som udgangspunkt for at beregne og sammenligne miljøpåvirkningen. Enheden skal repræsentere virksomhedens værditilbud, hvad enten det er et produkt eller en service. Dette kan være en fysisk genstand som et par bukser, en fødevare som en kop kaffe eller en abstrakt størrelse som en online-shop. Valget af enhed er afgørende for at kunne kvantificere og evaluere de øvrige dele i modellen på en konsistent måde.
    </p>
    <p class="mb-6">
        Du bør overveje: Hvilken enhed er det, vi taler om? Hvilke grupperinger kunne være relevante?
    </p>
    <p class="mb-6">
        <strong>BRUG </strong>
    </p>
    <p class="mb-6">
        Brugsfasen dækker den tid, hvor kunden anvender produktet eller tjenesten. Miljøpåvirkningen i denne fase kan omfatte energiforbrug, vedligeholdelse og reparation. Fokus bør være på at forstå og minimere ressourceforbruget samt tilbyde løsninger, der fremmer mere bæredygtig brug.
    </p>
    <p class="mb-6">
        Du bør overveje: Hvordan påvirker vores værditilbud miljøet under brug? Hvilke ressourcer bliver anvendt i brugsfasen? Hvilke ændringer er nødvendige for at minimere miljøpåvirkningen? Hvordan kan vi optimere den service, der understøtter produktet, så den belaster miljøet mindre?
    </p>
    <p class="mb-6">
        <strong>END-OF-LIFE </strong>
    </p>
    <p class="mb-6">
        Denne del handler om, hvad der sker med produktet, når kunden er færdig med at bruge det. Det inkluderer bortskaffelse, genbrug og genanvendelse. Målet er at reducere affald og fremme cirkulære løsninger, der forlænger materialernes levetid.
    </p>
    <p class="mb-6">
        Du bør overveje: Hvordan bortskaffes eller opbevares vores værditilbud, når det ikke længere bruges? Hvilke tiltag kan implementeres for at minimere miljøpåvirkningen ved slutningen af produktets livscyklus? Hvilke muligheder findes for genbrug eller genanvendelse muligheder - anvendes principperne for cirkulær økonomi – hvis ja, så beskriv hvordan der arbejdes hermed.
    </p>
    <p class="mb-6">
        <strong>DISTRIBUTION </strong>
    </p>
    <p class="mb-6">
        Distribution omfatter alle aktiviteter relateret til levering af produkter eller tjenester til kunden. Dette inkluderer valg af transportmetoder, optimering af logistik og reduktion af miljøpåvirkning fra transport. Det er vigtigt at evaluere både de direkte og indirekte miljømæssige omkostninger forbundet med distributionen.
    </p>
    <p class="mb-6">
        Du bør overveje: Hvilke metoder anvendes til levering af vores værditilbud? Hvordan kan vi optimere logistikken for at mindske belastningen? Energiforbruget kan defineres som 1) elektricitet - vedvarende/ikke vedvarende 2) brændstof – vedvarende/ikke vedvarende.
    </p>
    <p class="mb-6">
        <strong>MILJØFORDELE</strong>
    </p>
    <p class="mb-6">
        Formålet med denne del er at udforske og implementere initiativer, der reducerer virksomhedens negative miljøpåvirkning. Dette kan inkludere nye teknologier, innovative løsninger eller strategiske ændringer, der gør virksomheden mere bæredygtig.
    </p>
    <p class="mb-6">
        Du bør overveje: Hvilke konkrete muligheder har vi for at minimere miljøpåvirkningen? Hvilke strategiske ændringer kan gøre vores virksomhed mere bæredygtig? Hvordan kan vi integrere miljøhensyn i hele værdikæden?
    </p>
    <p class="mb-6">
        <strong>PRODUKTION </strong>
    </p>
    <p class="mb-6">
        Denne del dækker over alle de aktiviteter, der forvandler råvarer til færdige produkter eller understøtter levering af en service. Det omfatter produktionsprocesser, logistik og infrastruktur. Fokus bør ligge på de elementer af produktionen, der har den største miljøpåvirkning, og identificere muligheder for optimering eller innovation, der kan reducere miljøbelastningen.
    </p>
    <p class="mb-6">
        Du bør overveje: Hvilke processer er nødvendige for at producere vores værditilbud? Hvilke områder har de største optimeringsmuligheder? Er der alternative metoder, der kan reducere miljøpåvirkningen?
    </p>
    <p class="mb-6">
        <strong>MATERIALER </strong>
    </p>
    <p class="mb-6">
        Materialer refererer til de nøglekomponenter, der bruges til at skabe virksomhedens produkter eller levere dens tjenester. Dette omfatter både de fysiske råvarer og den nødvendige infrastruktur og information. Fokus bør være på materialer, der er essentielle for værdiskabelsen, og som har en væsentlig miljøpåvirkning. Ved at analysere materialerne kan man identificere potentielle muligheder for at reducere belastningen gennem substitutter eller mere bæredygtige alternativer.
    </p>
    <p class="mb-6">
        Du bør overveje: Hvilke materialer bruges til fremstillingen af vores produkter eller tjenester? Hvilke materialer har den største miljøbelastning? Er det muligt at erstatte disse materialer med mindre belastende alternativer?
    </p>
    <p class="mb-6">
        <strong>FORSYNINGER OG OUTSOURCING</strong>
    </p>
    <p class="mb-6">
        Denne del repræsenterer de ressourcer og tjenester, der købes eksternt og ikke indgår direkte i virksomhedens kerneaktiviteter. Dette kan inkludere energi, vand og andre ressourcer leveret af tredjeparter. Det er vigtigt at undersøge disse partneres miljøpåvirkning og vurdere, om der findes mere bæredygtige alternativer.
    </p>
    <p class="mb-6">
        Du bør overveje: Hvilke ressourcer og tjenester tilkøbes, som ikke direkte indgår i produktionen? Hvilken miljøpåvirkning har disse ressourcer og tjenester? Kan vi erstattes og/eller optimere brugen af disse ressourcer?
    </p>
    <p class="mb-6">
        <strong>MILJØPÅVIRKNING</strong>
    </p>
    <p class="mb-6">
        Denne del søger at beregne og analysere virksomhedens samlede miljøpåvirkning. Dette kan omfatte CO2-udledning, vandforbrug eller andre relevante parametre. Ved at opdele miljøpåvirkningen på tværs af produktets livscyklus kan virksomheden identificere de mest kritiske områder for forbedring.
    </p>
    <p class="mb-6">
        Du bør overveje: Hvordan kan vi måle og overvåge disse påvirkninger effektivt? Hvilke forbedringer kan implementeres for at reducere belastningen? Hvordan kan vi opnå en mere detaljeret forståelse af vores samlede miljøindflydelse?
    </p>
  `,
};







