import "./App.css";
import "react-toastify/dist/ReactToastify.min.css";

import React, {FC, useEffect} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import Header from "./components/Header";
import { HomePage, NotFoundPage } from "./pages/index";
import routes from "./routes";

const App: FC = () => {
  return (
    <div className="overflow-hidden h-screen flex flex-col">
      <div className="bg-mainBg inset-0 fixed w-full" />
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        {routes.map((route) => (
          <Route path={route.path} element={route.element} key={route.id} />
        ))}
        <Route path={"/*"} element={<NotFoundPage />} />
          <Route path="/was" element={<RedirectToAccessibility />} />
      </Routes>
      <ToastContainer autoClose={8000} position={toast.POSITION.TOP_RIGHT} />
    </div>
  );
};

const RedirectToAccessibility: FC = () => {
    useEffect(() => {
        window.location.href = "https://www.was.digst.dk/app-baeredygtighedsmodel-dk";
    }, []);
    return null;
};

export default App;
