import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { connect } from "react-redux";

import { Dispatch, RootState } from "../../store/store";

import { Button } from "../../components/core/Button";
import { CustomizedLabelInput } from "../../components/core/inputs/CustomizedLabelInput";
import { CustomizedLabelSelect } from "../../components/core/select/CustomizedLabelSelect";
import { INPUTS, OPTION_COLOR, SELECT_TYPES } from "../../constants/ui";
import { useNavigate } from "react-router";
import { RESET_PASSWORD_ROUTE } from "../../store/constants/route-constants";
import { useFormik } from "formik";
import { ICompanyUser } from "../../store/models/interfaces";
import * as Yup from "yup";
import { EDITOR_ROLE } from "../../constants/roles";
import { difference } from "lodash";
import { SelectOptionProps } from "../../components/core/Select";
import { ConfirmationModal } from "../../components/core/modals/confirmation-modal";
import { SHARE_CONFIRM_MODAL_DATA } from "../../store/constants/share-constants";
import {Link} from "react-router-dom";

const APP_URL = process.env.REACT_APP_API_URL;

const ProfilePageForm: FC<any> = ({
  signOut,
  updateProfile,
  uploadProfileImage,
  user,
  getCompanyUsers,
  companyUsers,
  isOwner,
  deleteAccess,
  deleteUser,
}) => {
  const navigate = useNavigate();
  const [showDeleteLayerGoalModal, setShowDeleteLayerGoalModal] =
    useState(false);

  useEffect(() => {
    if (isOwner) getCompanyUsers(user.company.id);
  }, []);

  const getOptionsByRole = () => {
    const filteredUsers = companyUsers.filter(
      (u: ICompanyUser) => u.role.value === EDITOR_ROLE
    );
    return filteredUsers.map((companyUser: ICompanyUser) => ({
      value: companyUser.id,
      label: companyUser.first_name + " " + companyUser.last_name,
      color: OPTION_COLOR,
    }));
  };

  const options = getOptionsByRole();

  const handleOnRemoveClicked = (changedOptions: any) => {
    const optionsValue: SelectOptionProps[] = changedOptions.map(
      (value: any) => value
    );
    const removedElement = difference(options, optionsValue)[0];
    deleteAccess({
      companyId: user.company.id,
      userId: removedElement.value,
    });
    const index = options.indexOf(removedElement);
    if (index !== -1) {
      options.splice(index, 1);
    }
  };

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event!.currentTarget!.files![0];
    const formData = new FormData();
    formData.append("file", file, file.name);
    uploadProfileImage(formData);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.username,
      company_name: user?.company?.name,
      cvr_number: user?.company?.vat_number,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email").required("Required"),
      company_name: Yup.string().required("Required"),
      cvr_number: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      await updateProfile({
        first_name: values.first_name,
        last_name: values.last_name,
        username: values.email,
        company_name: values.company_name,
        cvr_number: values.cvr_number,
      });
    },
  });

  const onDeleteUser = () => {
    // deleteUser()
    setShowDeleteLayerGoalModal(true);
  };
  const hideDeleteLayerGoalModal = () => {
    setShowDeleteLayerGoalModal(false);
  };
  const submitDeleteLayer = () => {
    deleteUser();
    setShowDeleteLayerGoalModal(false);
  };

  return (
    <>
      <form className="flex" onSubmit={formik.handleSubmit}>
        {/*<div className="w-1/3 items-center">*/}
        {/*<div className="flex justify-center items-center w-full">*/}
        {/*  {!user?.logo && (*/}
        {/*    <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-250 h-250 bg-gray-150 cursor-pointer rounded-10 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600">*/}
        {/*      <div className="flex flex-col justify-center items-center pt-5 pb-6">*/}
        {/*        <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" /></svg>*/}
        {/*      </div>*/}
        {/*      <input*/}
        {/*        id="dropzone-file"*/}
        {/*        type="file"*/}
        {/*        accept="image/png, image/jpeg"*/}
        {/*        onChange={(e) => onFileChange(e)}*/}
        {/*        multiple={false} className="hidden"*/}
        {/*      />*/}
        {/*    </label>*/}
        {/*  )}*/}
        {/*  {user?.logo && (*/}
        {/*    <div className="text-center underline text-blue-600">*/}
        {/*      <label htmlFor="dropzone-file" className="cursor-pointer">*/}
        {/*        <img*/}
        {/*          src={`${APP_URL}/${user?.logo}`}*/}
        {/*          className="w-250 h-250 rounded-10 object-cover"*/}
        {/*          alt="logo"*/}
        {/*        />*/}
        {/*        <input*/}
        {/*          id="dropzone-file"*/}
        {/*          type="file"*/}
        {/*          accept="image/png, image/jpeg"*/}
        {/*          onChange={(e) => onFileChange(e)}*/}
        {/*          multiple={false} className="hidden"*/}
        {/*        />*/}
        {/*      </label>*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*</div>*/}
        {/*<div className="w-[250px] mx-auto mt-6">*/}
        {/*  <Button*/}
        {/*    type={"button"}*/}
        {/*    onClick={signOut}*/}
        {/*    label="Log ud"*/}
        {/*    backgroundColor="bg-red-500"*/}
        {/*    hoverColor="hover:bg-red-800"*/}
        {/*    textColor="text-white"*/}
        {/*    height="h-82"*/}
        {/*    width="w-full"*/}
        {/*    disabled={false}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*<div className="w-2/3 items-center">*/}
        <div className="w-full flex items-center justify-center">
          <div className="w-1/2">
            <div className="flex mb-6">
              <div className="w-1/2 pr-60">
                <CustomizedLabelInput
                  id="company_name"
                  autoComplete="company-name"
                  type="text"
                  value={formik.values.company_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.company_name as string}
                  label="Virksomhedsnavn"
                  placeHolder="Lund Hjemmesider ApS"
                  styleType={INPUTS.DEFAULT_FULL_WIDTH}
                  backgroundColor="bg-gray-150"
                  disabled={false}
                />
              </div>
              <div className="w-1/2 pr-60">
                <CustomizedLabelInput
                  id="cvr_number"
                  autoComplete="cvr-number"
                  type="text"
                  value={formik.values.cvr_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.cvr_number as string}
                  label="CVR-nummer"
                  placeHolder="23857493"
                  styleType={INPUTS.DEFAULT_FULL_WIDTH}
                  backgroundColor="bg-gray-150"
                  disabled={false}
                />
              </div>
            </div>
            <div className="flex mb-6">
              <div className="w-1/2 pr-60">
                <CustomizedLabelInput
                  id="first_name"
                  autoComplete="first-name"
                  type="text"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.first_name as string}
                  label="Fornavn"
                  placeHolder="Anders"
                  styleType={INPUTS.DEFAULT_FULL_WIDTH}
                  backgroundColor="bg-gray-150"
                  disabled={false}
                />
              </div>
              <div className="w-1/2 pr-60">
                <CustomizedLabelInput
                  id="last_name"
                  autoComplete="last-name"
                  type="text"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.last_name as string}
                  label="Efternavn"
                  placeHolder="Poulsen"
                  styleType={INPUTS.DEFAULT_FULL_WIDTH}
                  backgroundColor="bg-gray-150"
                  disabled={false}
                />
              </div>
            </div>
            <div className="flex mb-60">
              <div className="w-1/2 pr-60">
                <CustomizedLabelInput
                  id="email"
                  autoComplete="email-address"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.email as string}
                  label="Emailadresse"
                  placeHolder="udvikling@lundhjemmesider.dk"
                  styleType={INPUTS.DEFAULT_FULL_WIDTH}
                  backgroundColor="bg-gray-150"
                  disabled={false}
                />
              </div>
              <div className="w-1/2 pr-60">
                <div className="grid justify-items-stretch">
                  <div>
                    <CustomizedLabelInput
                      id="password"
                      autoComplete="password"
                      type="password"
                      label="Adgangskode"
                      placeHolder="xxxxxoern"
                      styleType={INPUTS.DEFAULT_FULL_WIDTH}
                      backgroundColor="bg-gray-150"
                      disabled={true}
                    />
                  </div>
                  <div className="justify-self-end">
                    <p
                      className="cursor-pointer"
                      onClick={() => navigate(RESET_PASSWORD_ROUTE)}
                    >
                      Skift adgangskode
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex mb-6">
              <div className="w-full pr-60">
                {companyUsers.length > 0 && (
                  <CustomizedLabelSelect
                    label="Delt med"
                    type={SELECT_TYPES.GRAY}
                    optionList={options}
                    onChange={handleOnRemoveClicked}
                    selectedOption={options}
                    backgroundColor="#e9e9e9"
                  />
                )}
              </div>
            </div>
            <div className="flex mb-6 justify-between pr-60">
              <div className="w-[220px]">
                <Button
                  type={"button"}
                  onClick={signOut}
                  label="Log ud"
                  backgroundColor="bg-red-500"
                  hoverColor="hover:bg-red-800"
                  textColor="text-white"
                  height="h-82"
                  width="w-full"
                  disabled={false}
                />
              </div>
              <div className="w-[220px]">
                <Button
                  type={"button"}
                  onClick={onDeleteUser}
                  label="Slet bruger"
                  backgroundColor="bg-red-500"
                  hoverColor="hover:bg-red-800"
                  textColor="text-white"
                  height="h-82"
                  width="w-full"
                  disabled={false}
                />
              </div>
              <div className="w-[220px]">
                <Button
                  type={"submit"}
                  label="Gem"
                  backgroundColor="bg-green-200"
                  hoverColor="hover:bg-green-800"
                  textColor="text-white"
                  height="h-82"
                  width="w-full"
                  disabled={false}
                />
              </div>
            </div>
            <div>
              <a
                href="https://www.was.digst.dk/app-baeredygtighedsmodel-dk"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 underline hover:text-blue-800"
              >
                Tilgængelighedserklæring
              </a>
            </div>
          </div>
        </div>
      </form>
      <ConfirmationModal
        setShowModal={hideDeleteLayerGoalModal}
        submitRed={submitDeleteLayer}
        submitGreen={hideDeleteLayerGoalModal}
        showModal={showDeleteLayerGoalModal}
        title={SHARE_CONFIRM_MODAL_DATA.title}
        body={SHARE_CONFIRM_MODAL_DATA.body}
        redBtnLabel={SHARE_CONFIRM_MODAL_DATA.redBtnLabel}
        greenBtnLabel={SHARE_CONFIRM_MODAL_DATA.greenBtnLabel}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  companyUsers: state.user?.companyUsers || [],
  isOwner: state.user?.isOwner,
});

const mapDispatch = (dispatch: Dispatch) => ({
  signOut: dispatch.user.signOut,
  updateProfile: dispatch.user.updateProfile,
  uploadProfileImage: dispatch.user.uploadProfileImage,
  fullSignOut: dispatch.user.fullSignOut,
  getCompanyUsers: dispatch.user.getCompanyUsers,
  deleteAccess: dispatch.user.deleteAccess,
  deleteUser: dispatch.user.deleteUser,
});

export default connect(mapState, mapDispatch)(ProfilePageForm);
